<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-datepicker
            :range="true"
            label="제출기간"
            defaultStart="-6M"
            defaultEnd="6M"
            name="period"
            v-model="period"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="KORA_SUBMIT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="koraSubmitTypeCd"
            label="제출 구분"
            v-model="searchParam.koraSubmitTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="KORA_SUBMIT_LEVEL_CD"
            itemText="codeName"
            itemValue="code"
            name="koraSubmitLevelCd"
            label="작성 수준"
            v-model="searchParam.koraSubmitLevelCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="화학사고 예방관리계획서 목록"
      :columns="grid.columns"
      :gridHeight="grid.height"
      :data="grid.data"
      rowKey="policyId"
      :columnSetting="false"
      :expandAll="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn v-if="editable" label="등록" icon="add" @btnClicked="bookPopup" />
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'iso-review-report',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: '사업장',
            align: 'center',
            style: 'width:120px',
            sortable: true,
          },
          {
            name: 'documentTitle',
            field: 'documentTitle',
            label: '제목',
            align: 'left',
            type: "link",
            sortable: true,
          },
          {
            name: 'koraSubmitTypeName',
            field: 'koraSubmitTypeName',
            label: '제출구분',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'koraSubmitLevelName',
            field: 'koraSubmitLevelName',
            label: '작성수준',
            align: 'center',
            style: 'width:100px',
            sortable: true,
          },
          {
            name: 'documentDate',
            field: 'documentDate',
            label: '제출일자',
            align: 'center',
            style: 'width:150px',
            sortable: true,
          },
          {
            name: 'regName',
            field: 'regName',
            label: '작성자/작성일',
            align: 'center',
            style: 'width:300px',
            sortable: true,
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        startYmd: '',
        endYmd: '',
        koraSubmitTypeCd: null,
        koraSubmitLevelCd: null,
      },
      period: [],
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        top: '',
        isFull: true,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      // api scope
      this.listUrl = selectConfig.mdm.kora.document.list.url;
      this.getList();
    },
    getList() {
      if (this.period) {
        this.searchParam.startYmd = this.period[0];
        this.searchParam.endYmd = this.period[1];
      } else {
        this.searchParam.startYmd = '';
        this.searchParam.endYmd = '';
      }
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    linkClick(row) {
      this.bookPopup(row);
    },
    bookPopup(result) {
      this.popupOptions.target = () => import(`${"./koraDocumentDetail.vue"}`);
      this.popupOptions.title = '화학사고 예방관리계획서 상세';
      this.popupOptions.param = {
        koraDocumentId: result ? result.koraDocumentId : '',
      };
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
